*[data-layout="six1five"] {
  @include grid;

  @include mobile {
    grid-template-columns: minmax(290px, 1fr);
  }

  @include tablet-and-up {
    grid-template-columns: 6fr 1fr 5fr;
  }
}