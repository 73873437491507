*[data-layout="five2four1"] {
  @include grid;

  @include mobile {
    grid-template-columns: 1fr;
  }

  @include tablet-and-up {
    grid-template-columns: 5fr 2fr 4fr 1fr;
  }
}