// general styling and layout
.faqs {

  display: flex;

  @include mobile {
    flex-direction: column;

    &--category:not(:first-child) {
      padding-top: 3rem;
    }
  }

  @include tablet-and-up {
    @include evenGrid();

    &--title {
      grid-column: span 4;
    }

    &--container {
      grid-column: span 8;
    }
  }
}

// behaviour

.faqs {


  &--category {

    &--wrapper {
      cursor: pointer;

      .faqs--category--question::before {
        content: " ";
        background-image: url("/assets/img/icons/disk-full.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;

        transition: all .3s ease-in-out;
        transform: scale(1);
      }

      .faqs--category--answer {
        display: none;
      }
    }

    &--wrapper.active {
      .faqs--category--question::before {
        background-image: url("/assets/img/icons/disk-empty.svg");
      }

      .faqs--category--answer {
        display: block;

        &.list {
          display: list-item;
        }
      }
    }

    &--wrapper:hover:not(.active) {

      // .faqs--category--question {
      //   color: $text-alt;
      // }

      // .faqs--category--question::before {
      //   width: 1rem;
      //   margin-right: 1.25rem;
      //   margin-left: 0.25rem;
      // }
      .faqs--category--question::before {
        transform: scale(0.75);
      }

    }

    &--title {
      padding-bottom: 2rem;
      border-bottom: 1px solid white;
    }

    &--answer {
      font-size: 1rem;
      font-weight: 400;
      margin-left: 2rem;
      white-space: pre-wrap;

      & > a {
        font-size: 1rem;
        color: $text-link;
      }
    }
  }

  @include mobile {

    &--category--wrapper {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    &--category--question {
      margin-left: 2rem;
    }

    &--category--question::before {
      position: absolute;
      left: 0.75rem;
    }

  }

  @include tablet-and-up {
    &--category--title {
      font-size: 2rem;
      line-height: 2rem;
    }

    &--category:not(:first-child) {
      .faqs--category--title {
        margin-top: 3rem;
      }
    }

    &--category--wrapper {
      margin-left: -2rem;
      margin-top: 0.5rem;
    }

    &--category--question {
      display: inline-flex;
      margin: 1rem 0 1rem;
    }

    &--category--question::before {
      position: relative;
      top: 2px;
    }

    &--category--answer {
      margin-top: 0.625rem;
    }
  }
}