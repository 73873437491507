*[data-layout="five1six"] {
  @include grid;

  @include mobile {
    grid-template-columns: minmax(290px, 1fr);
  }

  @include tablet-and-up {
    grid-template-columns: 5fr 1fr 6fr;
  }
}