*[data-page="privacy-policy"] {
  .body {
    &--header {

      @include mobile {
        margin-top: 4rem;
      }

      @include tablet-and-up {
        margin-top: 6rem;
      }
    }
  }
}