.slideshow {
  // responsive layout
  @include mobile {
    @include evenGrid(6);
  }
  @include tablet-and-up {
    @include evenGrid;
  }

  &--image {
    position: relative;

    .content {
      position: absolute;
    }

    .foreground {
      position: relative;
    }

    img {
      display: block;
      width: 100%;
    }

    // responsive
    @include mobile {
      grid-column: span 6;
      margin-bottom: 2rem;
    }
    @include tablet {
      grid-column: span 5;
    }

    @include desktop {
      grid-column: span 5;
    }
  }

  &--text, &--HIT {
    max-width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > *:first-child {
      margin-top: 0;
      color: $text-shaded;

      & > a {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;

        &:hover, &:active {
          color: $white;
        }
      }
    }

    // responsive styling

    @include mobile {
      grid-column: span 6;
      > *:first-child {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    @include tablet {
      grid-column: 8 /span 5;
      > *:first-child {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    }

    @include desktop {
      grid-column: 8 / span 5;
      > *:first-child {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
  }

  &--HIT {
    font-size: 4rem;
    line-height: 4.75rem;
    display: inline;
    color: $text-dark;

    @include mobile {
      display: none;
    }

    & > img {
      margin-left: 1rem;
    }
  }
}

.slideshow.HIT {
  grid-template-rows: 300px;
  .slideshow--image {
    grid-row: span 2;
  }
}