*[data-page="how-it-works"] {
  //
  // Page title + text
  //
  .hit-intro {
    @include evenGrid;

    &--body {
      & > a {
        color: $text-link;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  @include mobile {
    .hit-intro {
      margin-top: 0;

      > * {
        grid-column: 1 /span 12;
      }

      &--header {
        margin-top: 4rem;
      }
    }
  }

  @include tablet-and-up {

    .page-body-wrapper {
      background-image: url('../../assets/img/content/hit-background.svg');
      background-position: right;
      background-repeat: no-repeat;
      background-size: 40%;
      max-width: 1440px;
      margin: 0 auto;
    }

    .hit-intro {
      max-width: $large-desktop;
      margin: -10rem auto 0;
      padding: 10rem $page-tab-gutter 0;
      width: 100%;

      > * {
        grid-column: 1 /span 5;
      }

      &--header {
        margin-top: 6rem;
      }
    }
  }

  //
  // Image explanation of how it works
  //
  .hit--mobile {
    @include mobile-only();
    justify-content: center
  }

  .hit--tablet {
    @include tablet-and-up-only(grid);

    margin-top: -7.5rem;

    & > *:nth-child(1) {
      margin-top: 15rem;
    }
  }

  //
  // Slideshow with CTA
  //
  @include desktop {
    .slideshow {
      margin-top: -5rem;
    }
  }
}