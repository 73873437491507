*[data-layout="about-us"] {
  .image-and-text:first-of-type {

    @include mobile {
      margin-top: 4rem;
    }

    @include tablet-and-up {
      margin-top: 6rem;
    }

    .image-and-text--text {
      justify-content: flex-start;

      & > h1 {
        margin-top: 0;
      }
    }
  }
}