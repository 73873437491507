* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 1rem;
  background: $grimace;
  color: $text-main;
}

// App layout
.App {

  @include mobile {

    & > * {
      padding: 0 $page-mob-gutter;
      margin-left: auto;
      margin-right: auto;
      max-width: $large-desktop;
    }

    > * + * {
      margin-top: 2.625rem;
    }
  }

  @include tablet-and-up {

    & > * {
      padding: 0 $page-tab-gutter;
      margin-left: auto;
      margin-right: auto;
      max-width: $large-desktop;
      width: 100%;
    }

    > * + * {
      margin-top: 3.75rem;
    }
  }
}


// paragraph

p, table, span {
  font-family: "UniversalSans", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

// Headers
@include mobile {
  h1 { font: $type-h3; letter-spacing: -0.07em;}
  h2 { font: $type-h3; letter-spacing: -0.07em;}
  h3 { font: $type-h3; letter-spacing: -0.07em;}
}

@include tablet-and-up {
  h1 { font: $type-h1; letter-spacing: -0.07em;}
  h2 { font: $type-h2; letter-spacing: -0.07em;}
  h3 { font: $type-h3; letter-spacing: -0.07em;}
}

// Link styling
a {
  font-family: "UniversalSans", sans-serif;
  color: $text-main;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;

  font-size: 1.5rem;
  line-height: 1.75rem;

  &:hover, &:active {
    text-decoration: underline;
  }
}

h1 > a, h2 > a, h3 > a {
  font: inherit;
  letter-spacing: inherit;
}

// Inputs and forms
.input, .label {
  font-family: "UniversalSans", sans-serif;
}

.input {
  background: $brand-off-white;

  border: 1px solid #96A2C2;
  border-radius: 5px;
  font-size: 1.4rem;

  &:focus {
    outline: none;
  }

  // responsive sizing

  @include mobile {
    height: 3rem;
  }

  @include tablet-and-up {
    height: 3.5rem;

  }
}

.label {
  font-size: 1.5rem;
  line-height: 1.8rem;
}

// pill button

.pill {
  -webkit-appearance: none !important;
  width: 10.5rem;
  height: 2.5rem;

  background: $rust;
  border: 0;
  border-radius: 4.8rem;

  color: $white;
  text-transform: uppercase;

  cursor: pointer;
}

// special style for images that are too long because of shadows
.reduced-bottom-margin {
  margin-bottom: -3rem;
}

img {
  max-width: 100%;
}