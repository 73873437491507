*[data-page="homepage"] {

  .title {
    text-shadow: 1px 1px 1px $bg-main;
  }

  .image-block {
    position: relative;
    z-index: 2;
  }

  .slideshow {
    position: relative;
    z-index: 3;

    @include mobile {
      margin-top: -20rem;
    }

    @include tablet-and-up {
      margin-top: -300px;
    }
  }

}
