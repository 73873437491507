.mailchimp-beta-form {
  display: grid;

  .info {
    .heading {
      margin-bottom: 15px;
    }

    .indicates-required.label {
      font-size: 1rem;

      & > span {
        font-size: inherit;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .mc-field-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 1rem 0;

      input {
        width: 100%;
      }

      &.input-group ul {
        list-style: none;
        padding: 0;

        li {
          display: flex;

          input {
            width: 1rem;
            margin-right: 1rem;
          }
        }
      }

      select {
        height: 3.5rem;
        font-size: 1.4rem;
      }
    }
  }

  @include up-to-desktop {
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;

    .info, form {
      grid-column: span 6;
    }

  }

  @include desktop {
    gap: 5rem;
    grid-template-columns: repeat(12, 1fr);

    .info {
      grid-column: 1 / span 5;
    }

    form {
      grid-column: 7 / span 6;
    }
  }
}