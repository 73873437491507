.image-and-text {

  &--image {
    align-self: center;

    & > img {
      max-width: 100%;
    }

    &.background {
      background-image: url('../../assets/img/content/image-background.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      display: flex;
      justify-content: center;

      @include tablet-and-up {
        & > * {
          max-height: 80vh;
        }
      }
    }
  }

  &--text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .alt {
      color: $text-shaded;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &--cta {
    margin: auto 0;
  };


  // responsive
  @include mobile {

    padding: 0;

    &--image {
      max-width: 100%;
      order: 3;

      padding: 0 1rem;

      &.extra-width {
        padding: 0;
        & > img {
          width: 110%;
          padding: 0 0 0 1rem;
        }
      }
    }

    &--text {
      padding: 0 1rem;

    }
  }

  @include tablet-and-up {
    &--image {
      max-width: 100%;
    }
  }
}