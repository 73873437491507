.footer {

  padding-top: 2rem;
  max-width: $large-desktop;
  margin: 0 auto;

  @include mobile {
    padding: 2rem $page-mob-gutter 6rem;
  }

  @include tablet-and-up {
    padding: 2rem $page-tab-gutter 6rem;
  }

  //
  // top half
  //
  &--top {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--logo {

    @include mobile {
      max-width: 5rem;
    }

  }

  &--tag-line {
    font-weight: 700;
    font-size: 4.375rem;
    line-height: 150px;
    letter-spacing: -0.24px;
    color: $text-shaded;

    margin: 0;
    margin-left: 1rem;
    flex-grow: 1;
    text-align: end;

    @include mobile {
      display: none;
    }

    @include tablet-and-up {
      display: unset;
      font-size: 4rem;
    }

    @include desktop {
      font-size: 4.375rem;
    }
  }

  //
  // bottom half
  //

  &--bottom {

    display: flex;
    flex-direction: row;
    padding-bottom: 3.5rem;
    align-items: center;

    & > * {
      text-align: center;
      flex-grow: 2;
      line-height: 3rem;
    }

    & > p {
      color: $text-main;
      font-weight: 600;
    }

    & > .left-align {
      flex-grow: 1;
      text-align: start;
    }

    & > .right-align {
      flex-grow: 1;
      text-align: end;
    }

    // responsive

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1.5rem;

      & > * {
        line-height: 2rem;
        margin-bottom: 1rem;
      }

      .footer--copyright {
        font-size: 0.75rem;
        color: $text-shaded;
      }
    }

    @include tablet-and-up {
      margin-top: 3rem;
      height: 3rem;

      &--mobile-link {
        display: none;
      }

      & > * {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    @include desktop {
      & > * {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}