.title {
  @include evenGrid(12);

  @include mobile {
    &--header {
      grid-column: 1 / span 12;
    }

    &--buttons {
      grid-column: 1 / span 12;
    }
  }

  @include tablet-and-up {
    &--header {
      grid-column: 1 / span 6;
    }

    &--buttons {
      grid-column: 1 / span 6;
    }
  }

  @include desktop {
    &--header {
      grid-column: 1 / span 5;
    }

    &--buttons {
      grid-column: 1 / span 5;
    }
  }
}