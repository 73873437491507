@font-face {
  font-family: 'UniversalSans';
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/UniversalSans-v1-2-0-10-20-111111112111-01-300-TRIAL.ttf") format('truetype');
}

@font-face {
  font-family: 'UniversalSans';
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/UniversalSans-v1-2-0-10-20-111111112111-01-400-TRIAL.ttf") format('truetype');
}

@font-face {
  font-family: 'UniversalSans';
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/UniversalSans-v1-2-0-10-20-111111112111-01-500-TRIAL.ttf") format('truetype');
}

@font-face {
  font-family: 'UniversalSans';
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/UniversalSans-v1-2-0-10-20-111111112111-01-600-TRIAL.ttf") format('truetype');
}

@font-face {
  font-family: 'UniversalSans';
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/UniversalSans-v1-2-0-10-20-111111112111-01-700-TRIAL.ttf") format('truetype');
}

@font-face {
  font-family: 'UniversalSans';
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/UniversalSans-v1-2-0-10-20-111111112111-01-800-TRIAL.ttf") format('truetype');
}