.mailchimp-newsletter {
  max-width: 100%;
  background-color: $bg-alt;
  color: $text-dark;
  margin-top: 4rem;

  .mailchimp-newsletter-form {
    padding-top: 2rem;
    max-width: $large-desktop;
    margin: 0 auto;

    @include mobile {
      padding: 2rem $page-mob-gutter;
    }

    @include tablet-and-up {
      padding: 2rem $page-tab-gutter;
    }


    @include mobile {
      display: flex;
      flex-direction: column;
      gap: calc(2 * #{$page-mob-gutter});

      .info {
        h2 {
          margin: 0;
        }
      }

      .validate {
        #mc_embed_signup_scroll {
          display: flex;
          flex-direction: column;
          gap: $page-mob-gutter;

          .mc-field-group, .mc-field-group > input {
            width: 100%;
          }
        }
      }
    }

    @include tablet-and-up {
      display: flex;
      flex-direction: column;
      gap: $page-tab-gutter;

      .info {
        h2 {
          margin: 0;
        }
      }

      .validate {
        #mc_embed_signup_scroll {
          display: flex;
          flex-direction: column;
          gap: $page-mob-gutter;
          max-width: 50rem;

          .mc-field-group, .mc-field-group > input {
            width: 100%;
          }
        }
      }
    }

    @include desktop {
      display: flex;
      gap: $page-tab-gutter;

      .info {
        h2 {
          margin: 0;
        }
      }
    }
  }
}

