
.home {
  position: relative;

  @include mobile {
    height: 1450px;
    margin-top: 4rem;
  }

  @include tablet-and-up {
    height: 1800px;
  }

  & > .title--wrapper {
    height: 112rem;

    @include grid;

    @include mobile {
      height: 90rem;
      grid-template-rows: 1fr 1fr 1fr;
    }

    @include tablet-and-up {
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    & > .title {
      @include mobile {
        & > * {
          margin: 0;
        }
      }

      &--header {
        @include mobile {
          grid-column: 1 / span 11;
          padding: 0 1rem;
        }
      }
    }

    & > .title, & > .quote {
      position: relative;
      z-index: 2;
    }

    & > .quote {
      @include evenGrid;

      text-shadow: 1px 1px 1px $bg-main;
      .quote--text   { color:  $text-quote;  }
      .quote--author { color:  $text-shaded; }

      @include mobile {
        height: 15rem;
        align-self: flex-end;
        order: 3;

        .quote--text, .quote--author { grid-column: 1 / span 12; }

        .quote--text {
          font-size: 1.5rem;
          line-height: 1.75rem;
        }

        .quote--author {
          font-size: 1.5rem;
          line-height: 1.75rem;
        }
      }

      @include tablet-and-up {
        .quote--text, .quote--author { grid-column: 7 / span 6; }

        .quote--text {
          font-size: 3.375rem;
          line-height: 4rem;
          margin: 0;
        }

        .quote--author {
          font-size: 1.875rem;
          line-height: 2.25rem;
          margin: 0;
        }
      }
    }
  }

  & > .image--wrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

    @include mobile {
      height: 90rem;
      background-image: url("../../assets/img/content/home-background-mobile.svg");
      background-size: 24rem;
      background-position: center top;
      background-repeat: no-repeat;
      background-position-y: -5rem;
      padding: 0 $page-mob-gutter;
      margin: 0 0-$page-mob-gutter;
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 40%;
      z-index: 2;

      padding: 0;
      margin: 0;
    }

    // Image positioning

    & > *:nth-child(1){
      @include mobile {
        top: 45%;
        left: 61%;
        max-width: 50%;
      }

      @include tablet-and-up {
        top: 2%;
        left: 45%;
        max-width: 30%;
      }
    }
    & > *:nth-child(2){
      @include tablet-and-up-only;
      top: 15%;
      left: 67%;
      max-width: 30%;
    }
    & > *:nth-child(3){
      @include mobile {
        top: 32%;
        left: 13%;
        max-width: 65%;
      }
      @include tablet-and-up {
        top: 32%;
        left: 14%;
      }
    }
    & > *:nth-child(4){
      @include mobile {
        top: 47%;
        left: -5%;
        max-width: 65%;
      }
      @include tablet-and-up {
        top: 57%;
        left: 0%;
      }
    }
    & > *:nth-child(5){
      @include tablet-and-up-only;
      top: 80%;
      left: 51%;
      max-width: 45%;
    }
    & > *:nth-child(6){
      @include mobile {
        top: 62%;
        left: 30%;
        max-width: 65%;
      }
      @include tablet-and-up {
        top: 86%;
        left: 15%;
      }
    }
    & > *:nth-child(7){
      @include tablet-and-up-only(block);

      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      z-index: 1;
    }
  }
}