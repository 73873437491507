.image-block{
  width: 100vw;
  height: 1280px;
  max-width: 100% !important;

  margin-top: 0 !important;

  background-color: $bg-alt;
  color: $text-dark;

  position: relative;
  overflow: hidden;

  @include tablet-and-up {
    padding-bottom: 300px;
  }

  //
  // desktop styling
  //
  &--desktop {
    @include tablet-and-up-only;
    height: 100%;
    align-items: center;
    max-width: $large-desktop;
    margin: 0 auto;
  }

  &--main-image, &--image-2, &--image-3 {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    top: 0;

    background-image: url('../../assets/img/content/home-7.webp');
    background-size: cover;
    background-repeat: no-repeat;

    position: absolute;
    z-index: 1;

    @include tablet-and-up {
      margin: 0 -$page-tab-gutter;
    }

    @media screen and (min-width: 1296px) {
      margin: 0 calc( 0px - (100vw - 1280px) / 2 );
    }
  }


  &--image-2, &--image-3 {
    background-size: contain;
    opacity: 0;
  }

  &--image-2 {
    background-image: url('../../assets/img/content/home-8.webp');
  }

  &--image-3 {
    background-image: url('../../assets/img/content/home-9.webp');
  }

  &--images-wrapper {
    width: 100vw;
    height: 100vh;
    margin: 0 -3rem;

    & > * {
      position: absolute;
      opacity: 0;
    }

    & > *:nth-child(1) {
      z-index: 2;
    }

    & > *:nth-child(2) {
      z-index: 3;
    }
  }

  &--body {
    position: relative;
    bottom: 0rem;
    left: 3rem;
    z-index: 5;

    width: calc(100% - 6rem);

    transform: translateY(15rem);

    text-shadow: 1px 1px 1px $bg-alt;

    @include evenGrid(12);
  }

  &--title {

    @include tablet {
      font-size: 4rem;
      line-height: 5rem;

      grid-column: 1 / span 6;
    }

    @include desktop {
      font-size: 5rem;
      line-height: 6rem;

      grid-column: 1 / span 6;
    }

    @include large-desktop {
      grid-column: 1 / span 5;
      margin-top: 0;
    }
  }

  &--text {
    opacity: 0;
    font-size: 2rem;
    line-height: 2.375rem;
    grid-column: 1 / span 5;
    margin-top: 0;
  }

  &--line-break {
    grid-column: 6 / span 6;
  }

  &--link {
    opacity: 0;

    & > a {
      font-size: 4rem;
      line-height: 4.5rem;
      color: $text-dark;

      & > img {
        margin-left: 1rem;
        width: 2rem;
      }
    }

    grid-column: 8 / span 5;
  }

  //
  // mobile styling
  //
  &--mobile {
    @include mobile-only;
    width: 100%;
    height: 100%;
    padding-bottom: 25rem;

    &--body {
      display: flex;
      flex-direction: column;
    }

    &--title {
      font-size: 4rem;
      line-height: 4.5rem;
      margin-bottom: 0;
    }

    &--text {
      opacity: 1;
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin: 3rem 0;
    }

    &--link {
      opacity: 1;
      font-size: 1.75rem;
      line-height: 2rem;
      color: $text-dark;

      & > img {
        margin-left: 1rem;
        width: 2rem;
      }
    }

  }

  // responsive

  @include mobile {
    height: unset;
  }
}