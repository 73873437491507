.header {
  display: flex;
  flex-direction: row;

  &--nav {
    display: flex;
    justify-content: flex-end;
    flex-grow: 2;

    & > * {
      color: $text-shaded;
      text-align: end;
      margin: 0 1.75rem
    }

    @include tablet {
      & > * {
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin: 0 auto;
      }
    }

    @include desktop {
      & > * {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }

  &--mobile-menu {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  &--toggle-btn {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: $bg-main;
    background-color: $bg-alt;
    font-weight: 600;
    height: 2rem;
    border-radius: 1.5rem;
    border: none;
    padding: 0 1rem;

    & > * + * {
      margin-left: 1.5rem;
    }
  }

  &--hamburger-wrapper {
    min-width: 1rem;
    max-width: 1rem;
    position: relative;
    height: 1rem;

    &.close {
      & > * {
        width: 1rem;
      }

      & > *:nth-child(1) { transform: translate(0, 4px) rotate(45deg) }
      & > *:nth-child(2) { display: none; }
      & > *:nth-child(3) { transform: translate(0, -4px) rotate(-45deg) }
    }
  }

  &--hamburger {
    display: block;
    height: 2px;
    position: absolute;
    right: 0;
    background-color: $bg-main;
    width: 100%;
    width: 1rem;
  }

  &--hamburger:nth-child(1) { top: 3px;  }
  &--hamburger:nth-child(2) { top: 7px;  }
  &--hamburger:nth-child(3) { top: 11px; }

  &--mobile-nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    top: 10rem;
    left: 0;
    padding-left: 1rem;

    width: calc(100vw - 1rem);
    height: 90vh;
    background-color: $bg-main;

    & > * {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 0.75rem 0;
    }
  }

  // Responsive

  @include mobile {
    padding-top: 2.625rem;

    &--nav {
      display: none;
    }

    &--mobile-menu {
      display: flex;
    }
  }

  @include tablet-and-up {
    padding-top: 5.75rem;

    &--nav {
      display: flex;
    }

    &--mobile-menu {
      display: none;
    }
  }
}