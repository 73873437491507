.cta {
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;

    & > * {
      max-width: 14rem;
    }
  }
}