
.pricing {

  &--text {
    color: $text-alt;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &--table {

    table {
      width: 100%;
      border-bottom: 1px solid $ui-low;
      padding-bottom: 2.25rem;
    }

    th {
      font-size: 0.875rem;
      line-height: 1.875rem;
      color: $ui-low;
    }

    td {
      font-size: 1.125rem;
      line-height: 2.125rem;
    }

    td:nth-child(2) {
      font-weight: 400;
    }
  }
}