
//
// Breakpoints
//

@mixin mobile () {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet () {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin tablet-and-up () {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop () {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin up-to-desktop () {
  @media screen and (max-width: 1023px) {
    @content;
  }
}


@mixin mobile-only ($mode: flex) {
  display: none !important;
  @include mobile {
    display: $mode !important;
  }
}

@mixin tablet-and-up-only ($mode: flex) {
  display: none !important;
  @include tablet-and-up {
    display: $mode !important;
  }
}

@mixin up-to-desktop-only ($mode: flex) {
  display: none !important;
  @include up-to-desktop {
    display: $mode !important;
  }
}

@mixin desktop-only ($mode: flex) {
  display: none !important;
  @include desktop {
    display: $mode !important;
  }
}

// special mixin for large desktops
@mixin large-desktop () {
  @media screen and (min-width: $large-desktop) {
    @content;
  }
}

// special mixin for long viewports
@mixin long-viewport () {
  @media screen and (min-height: 1024px) {
    @content;
  }
}

@mixin grid () {
  display: grid;
  gap: $grid-gap;
}

@mixin evenGrid ($columns: 12) {
  @include grid;
  grid-template-columns: repeat($columns, 1fr);
}