.body {
  &--body {
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 18px;

    & > a {
      color: $text-link;
      font-size: 14px;
      line-height: 18px;
    }
  }
}