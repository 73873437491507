.contact-form {
  display: grid;

  // responsive layout
  @include up-to-desktop {
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
  }

  @include desktop {
    gap: 5rem;
    grid-template-columns: repeat(12, 1fr);
  }

  &--text {
    grid-column: span 6;

    .email-link {
      color: $rust;
      text-decoration: underline;

      @include mobile {
        font: $type-h3;
      }

      @include tablet-and-up {
        font: $type-h1;
      }
    }

    @include up-to-desktop {
      margin-bottom: 0;
    }
  }

  &--subtitle {
    @include tablet-and-up {
      margin-top: 8rem;
    }
  }

  &--body {
    grid-column: span 6;

    display: flex;
    flex-direction: column;

    max-width: 100%;

    > * {
      display: flex;
      flex-direction: column;
    }

    > *:first-child {

      // responsive
      @include desktop {
        margin-top: 2.6rem;
      }
    }

    div + div {
      margin-top: 1.5rem;
    }
  }
}