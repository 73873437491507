// Palette
$black:              #b5d8d8;
$dark:               #43575a;
$grey:               #8b9799;
$light:              #b6bebf;
$pale:               #e1e5e5;
$off-white:          #f6f7f7;
$white:              #ffffff;

$ui-low: #CECECE;


// Brand colors
$grimace:         #6501E5;
$lavendar:        #D1C0F3;
$mustard:         #F3D861;
$dust:            #DCAC96;
$rust:            #D67158;
$lilac:           #6F91E8;
$brand-off-white: #FAF6F5;

// Text Colors
$text-main:     $white;
$text-alt:      $mustard;
$text-shaded:   $lavendar;
$text-quote:    $dust;
$text-dark:     $grimace;
$text-link:     $rust;

// Fill colors
$bg-main: $grimace;
$bg-alt:  $lavendar;

// Misc dimensions
$page-gutter:     8rem;
$page-tab-gutter: 3rem;
$page-mob-gutter: 1rem;
$header-height:   20rem;
$grid-gap:        0.5rem;
$large-desktop:   1280px;  //equiv 80rem


// Typographical presets
$type-h1: 700 3.125rem/3.75rem 'UniversalSans', sans-serif;
$type-h2: 700 2.625rem/3.125rem 'UniversalSans', sans-serif;
$type-h3: 700 1.875rem/2.25rem 'UniversalSans', sans-serif;